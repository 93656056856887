@import url('https://fonts.googleapis.com/css?family=Poppins:ital,wght@0,400;0,600;0,800;1,400;1,600;1,700|Roboto:400,900&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

h1,h2,h3,h4 {
  @apply font-black font-sans mb-2;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}

.markdown p {
  @apply mb-4;
}

.markdown code {
  @apply bg-gray-100 p-0.5;
}

.markdown a[href] {
  @apply text-yellow-700 underline;
}

.markdown ul a[href] {
  @apply inline-block py-1.5;
}

.markdown ol, ul {
  @apply ml-4 mb-4;
}

.markdown ol {
  @apply list-decimal;
}

.markdown ul {
  @apply list-disc;
}

.markdown img {
  @apply my-2;
}
